<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ data.name ? data.name : "Новый" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <v-row class="mt-3">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true }"
              @validate="validate($event)"
            />
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <a-btn-status v-if="getAccess(`invite.status`)" :items="statusItems" @click="changeStatus($event)" :disabled="!canStatus" />

        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>
        <a-btn-delete v-if="id" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, genModel, getAccess } from "../../components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl, genModel, getAccess],
  components: {},
  props: {
    value: Boolean,
    id: Number,
  },
  data() {
    return {
      modelForm: null,
      m: this.$store.getters["config/get"].models.invite,
      api: "/mechti/invite_registration",
      removeDialogShow: false,
      showTests: false,
    };
  },
  computed: {
    config() {
      return this.m.config.form;
    },
    model() {
      return this.calcModelConfig(this.config);
    },
    canStatus() {
      return !this.loading && !this.isChanged && this.data.id;
    },
    statusItems() {
      if (!this.data.status) return [];
      let items = [];
      let res = [];
      const statuses = this.m.statuses;
      const statusCur = statuses.find((el) => el.value == this.data.status);
      if (statusCur && statusCur.next && statuses) {
        let arr = statusCur.next;
        res = statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));
      return items;
    },
    statusCurrent() {
      let statusCur = this.m.statuses.find((el) => el.value == this.data.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
  },
  created() {
    this.updateData(this.id);
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },
  methods: {
    saveTest(e) {
      let data = { id: this.id, test_data: e };
      this.post(data, this.api);
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
    async changeStatus(status) {
      let id = this.data.id;
      if (!id) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Что-то пошло не так....",
        });
        return;
      }

      if (status.value == 2)
        if (await this.validateAll(this.data, true)) {
        } else return;
      this.loading = true;
      let data = { id, status: status.value };
      let response;
      try {
        response = await this.$axios.post(this.api, data);
      } catch (error) {
        response = error;
      }
      this.loading = false;
      status = response.data.status == "ok";
      if (status) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      } else {
        this.$root.$emit("show-info", {
          text: "Ошибка " + response?.data?.message,
          type: "error",
        });
      }
    },
  },
};
</script>
